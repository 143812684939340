<template>
  <span
    :class="{
      'status-primary': type === 'primary',
      'status-secondary': type === 'secondary',
      'status-warning': type === 'warning',
      'status-success': type === 'success',
      'status-danger': type === 'danger'
    }"
    class="ra-badge"
    v-on="$listeners"
    v-text="text"
  />
</template>

<script>
export default {
  name: 'RaBadge',

  props: {
    text: {
      type: String,
      default: () => '0'
    },

    type: {
      type: String,
      default: () => 'primary'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/scss/variables.scss';

  .ra-badge {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 50px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 5px 10px 5px 0;
    line-height: 1;
    cursor: pointer;
    font-size: 12px;
  }

  .status-primary {
    background: $primaryYellowShade1;
    color: black;
    &:before,
    &:after {
      background: $primaryYellowShade4;
    }
  }

  .status-secondary {
    background: $secondaryBlueShade1;
    color: $secondaryBlueShade5;
    &:before,
    &:after {
      background: $secondaryBlueShade4;
    }
  }

  .status-warning {
    background: $warningYellowShade1;
    color: $warningYellowShade4;
    &:before,
    &:after {
      background: $warningYellowShade4;
    }
  }

  .status-success {
    background: $successGreenShade1;
    color: $successGreenShade4;
    &:before,
    &:after {
      background: $successGreenShade4;
    }
  }

  .status-danger {
    background: $dangerRedShade1;
    color: $dangerRedShade4;
    &:before,
    &:after {
      background: $dangerRedShade4;
    }
  }
</style>
