export default [
  // {
  //   role: 'Founder',
  //   company: 'Retrovisor',
  //   from: '2023-02',
  //   to: 'now',
  //   total: '',
  //   domain: 'Task management tool',
  //   stack: [
  //     {
  //       name: 'Solid.js',
  //       color: 'danger'
  //     },
  //     {
  //       name: 'AI',
  //       color: 'primary'
  //     },
  //     {
  //       name: 'Tailwind',
  //       color: 'secondary'
  //     }
  //   ],
  //   leave: false
  // },
  {
    role: 'Lead Frontend Engineer',
    company: 'FixIQ',
    from: '2022-04',
    to: 'now', // 2024-08
    total: '- 2 years 8 months',
    domain: 'SAAS',
    stack: [
      {
        name: 'Vue',
        color: 'success'
      },
      {
        name: 'Solid.js',
        color: 'danger'
      },
      {
        name: 'Lit',
        color: 'secondary'
      }
    ],
    leave: false // 'Electricity issues in Ukraine'
  },
  {
    role: 'Senior Frontend Engineer',
    company: 'Divante',
    from: '2021-07',
    to: '2021-12',
    total: '- 5 months',
    domain: 'Ecommerce',
    stack: [
      {
        name: 'Vue',
        color: 'success'
      },
      {
        name: 'Vue Storefront',
        color: 'success'
      }
    ],
    leave: 'Company is too big for maintaining relationships between teams (over ~2000 devs)'
  },
  {
    role: 'Lead Frontend Engineer',
    company: 'Acteamo',
    from: '2021-01',
    to: '2021-07',
    total: '- 7 months',
    domain: 'Management tools',
    stack: [
      {
        name: 'Vue',
        color: 'success'
      },
      {
        name: 'Dart',
        color: 'secondary'
      }
    ],
    leave: 'I have done everything that I can as Lead and as a Developer'
  },
  {
    role: 'Senior JavaScript Engineer',
    company: 'Yodvisor',
    from: '2020-03',
    to: '2020-09',
    total: '- 6 months',
    domain: 'Messaging, Voice/Video chats, Streaming',
    stack: [
      {
        name: 'Vue',
        color: 'success'
      },
      {
        name: 'WebRTC',
        color: 'danger'
      },
      {
        name: 'Electron',
        color: 'secondary'
      },
      {
        name: 'C++',
        color: 'danger'
      }
    ],
    leave: 'Product has been sold'
  },
  {
    role: 'Senior JavaScript Developer',
    company: 'GRISSLI',
    from: '2019-08',
    to: '2020-03',
    total: '- 8 months',
    domain: 'CRM, Videocalls, Banking',
    stack: [
      {
        name: 'Vue',
        color: 'success'
      },
      {
        name: 'Nuxt',
        color: 'success'
      },
      {
        name: 'WebRTC',
        color: 'danger'
      },
      {
        name: 'C',
        color: 'primary'
      },
    ],
    leave: 'COVID-19 lockdown reduces 99% of company income, and developers lost their job'
  },
  {
    role: 'Middle JavaScript Developer',
    company: 'OMG - Ecommerce solutions',
    from: '2017-09',
    to: '2019-08',
    total: '- 2 years',
    domain: 'Ecommerce',
    stack: [
      {
        name: 'Vue',
        color: 'success'
      },
      {
        name: 'Electron',
        color: 'secondary'
      },
      {
        name: 'WebRTC',
        color: 'danger'
      }
    ],
    leave: 'Professional growing'
  },
  {
    role: 'Middle JavaScript Developer',
    // company: 'Yandex',
    company: 'NDA',
    from: '2015-02',
    to: '2017-09',
    total: '- 2 years 8 months',
    domain: 'CRM, browser extensions, remote control',
    stack: [
      {
        name: 'JavaScript',
        color: 'success'
      },
      {
        name: 'Electron',
        color: 'secondary'
      },
      {
        name: 'Java',
        color: 'warning'
      },
      {
        name: 'C++',
        color: 'danger'
      },
      {
        name: 'Vue',
        color: 'success'
      },
    ],
    leave: 'Years of amazing experience. Left to explore other domains'
  },
  {
    role: 'Founder & Frontend Developer',
    company: 'AXUA',
    from: '2014-06',
    to: '2015-01',
    total: '- 7 months',
    domain: 'CRM, Ecommerce',
    stack: [
      {
        name: 'React',
        color: 'secondary'
      },
      {
        name: 'ExtJS',
        color: 'success'
      },
      {
        name: 'Laravel',
        color: 'danger'
      }
    ],
    leave: 'Maintaining business process are not my thing, and I decided to continue as a developer'
  },
  {
    role: 'Junior JavaScript Developer',
    company: 'Advertising agency',
    from: '2012-07',
    to: '2014-06',
    total: '- 1 year 11 months',
    domain: 'Banking',
    stack: [
      {
        name: 'ExtJS',
        color: 'success'
      },
      {
        name: 'Angular',
        color: 'danger'
      }
    ],
    leave: 'Crunching, burnout after months of overtime'
  }
]
