<template>
  <div class="ra-basic ra-basic-text">
    <h2>Work history: {{ ago.js }} years</h2>
    <div>
      <div id="timeline-content">
        <ul class="timeline">
          <li
            v-for="(item, key) in work"
            :key="key"
            class="event"
          >
            <div
              v-if="item.company === 'FixIQ'"
              class="ra-fixiq-logo ra-timeline-company"
            >
              <img
                src="@/assets/logos/fixiq.svg"
                width="160"
                alt="FixIQ"
              >
            </div>
            <div
              v-else-if="item.company === 'Divante'"
              class="ra-divante-logo ra-timeline-company"
            >
              <img
                src="@/assets/logos/divante.svg"
                width="160"
                alt="Divante"
              >
              <small class="ra-small-aka">
                aka
              </small>
              <img
                src="@/assets/logos/storefront.svg"
                width="180"
                alt="Storefront"
              >
            </div>
            <div
              v-else-if="item.company === 'Acteamo'"
              class="ra-acteamo-logo ra-timeline-company"
            >
              <img
                src="@/assets/logos/acteamo.png"
                width="160"
                alt="Acteamo"
              >
            </div>
            <div
              v-else-if="item.company === 'Yodvisor'"
              class="ra-timeline-company"
            >
              <img
                src="@/assets/logos/logo-purple-light.svg"
                width="250"
                alt="Yodvisor"
              >
            </div>
            <div
              v-else-if="item.company === 'GRISSLI'"
              class="ra-grissli-logo ra-timeline-company"
            >
              <img
                src="@/assets/logos/grissli.svg"
                width="240"
                alt="GRISSLI"
              >
            </div>
            <div
              v-else-if="item.company === 'Yandex'"
              class="ra-timeline-company"
            >
              <img
                src="@/assets/logos/yandex.svg"
                width="200"
                alt="Yandex"
              >
            </div>
            <div
              v-else
              class="ra-timeline-company"
            >
              {{ item.company }}
            </div>
            <br />
            <div class="ra-timeline-role">
              {{ item.role }}
            </div>
            <p class="ra-timeline-time">
              {{ item.from }} - {{ item.to }} {{ item.total }}
            </p>
            <p class="ra-timeline-domain">
              Domain: <b>{{ item.domain }}</b>
            </p>
            <p class="ra-timeline-tip">
              Tech stack
            </p>
            <p class="ra-timeline-stack">
              <RaBadge
                v-for="(tag, tagKey) in item.stack"
                :text="tag.name"
                :key="tagKey"
                :type="tag.color"
              />
            </p>
            <RaButton
              v-if="!item.showLeave && item.leave"
              text="Reason of leave"
              size="small"
              type="warning"
              @click="item.showLeave = !item.showLeave"
            />
            <p
              v-if="item.showLeave && item.leave"
              class="ra-timeline-tip"
            >
              Reason of leave
            </p>
            <p
              v-if="item.showLeave && item.leave"
              class="ra-timeline-leave"
            >
              {{ item.leave }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import work from '@/data/work'


import RaButton from '@/components/elements/RaButton.vue'
import RaBadge from '@/components/elements/RaBadge.vue'

export default {
  name: 'WorkHistory',

  components: {
    RaButton,
    RaBadge
  },

  metaInfo: {
    title: 'Work history',
    titleTemplate: '%s | Roman Almazov',

    meta: [
      {
        name: 'description',
        content: 'Work history timeline from 2012'
      },
      { name: 'og:title', content: 'Work history | Roman Almazov' },
      { name: 'twitter:title', content: 'Work history | Roman Almazov' },
      { name: 'og:description', content: 'Work history timeline from 2012' },
      {
        name: 'twitter:description',
        content: 'Work history timeline from 2012'
      },
      { name: 'og:url', content: 'https://ralmaz.pro/work-history' },
      { name: 'twitter:url', content: 'https://ralmaz.pro/work-history' }
    ]
  },

  data: () => ({
    work: [],

    ago: {
      js: 9,
      jsFrom: '2012-03-10',
      vue: 4,
      vueFrom: '2015-08-11'
    }
  }),

  mounted() {
    this.work = work.map(e => {
      e.showLeave = false
      return e
    })
    this.dataRanges()
  },

  methods: {
    dataRanges() {
      this.ago.js = this.calculateYears(new Date(this.ago.jsFrom))
      this.ago.vue = this.calculateYears(new Date(this.ago.vueFrom))
    },

    calculateYears(start) {
      const ageDifMs = Date.now() - start
      const ageDate = new Date(ageDifMs)
      return Math.abs(ageDate.getFullYear() - 1970)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../scss/variables.scss';

  .ra-timeline-company {
    font-size: 34px;
    font-weight: 900;
    color: black;
  }
  .ra-body-dark {
    .ra-timeline-company {
      color: #FFC729;
    }
  }
  .ra-timeline-link {
    font-size: 20px;
    font-weight: 600;
  }
  .ra-timeline-role {
    font-size: 26px;
    font-weight: 600;
  }
  .ra-timeline-time {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.774);
    font-weight: 600;
  }

  .ra-timeline-leave {
    font-size: 18px;
    background-color: $warningYellowShade4;
    color: black;
    padding: 3px 10px;
    font-weight: 400;
    border-radius: $borderRadius;
  }

  .ra-timeline-stack {
    padding-top: 0;
    margin-top: 0;
  }

  .ra-timeline-domain {
    font-size: 16px;
  }

  .ra-timeline-tip {
    color: black;
    font-weight: 400;
    font-size: 10px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .timeline {
    border-left: 4px solid $primaryColor;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    color: rgba(255, 255, 255, 0.8);
    margin: 10px auto;
    letter-spacing: 0.5px;
    position: relative;
    line-height: 1.4em;
    font-size: 1.03em;
    padding: 50px;
    list-style: none;
    text-align: left;
    font-weight: 100;
  }
  .timeline h1 {
    letter-spacing: 1.5px;
    font-weight: 100;
    font-size: 1.4em;
  }
  .timeline h2,
  .timeline h3 {
    letter-spacing: 1.5px;
    font-weight: 400;
    font-size: 1.4em;
  }
  .timeline .event {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
    padding-bottom: 25px;
    margin-bottom: 50px;
    position: relative;
  }
  .ra-body-light .timeline .event {
    border-bottom: 1px dashed rgb(0, 0, 0, 0.1);
  }
  .timeline .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }
  .timeline .event:before,
  .timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
  }
  .timeline .event:after {
    box-shadow: 0 0 0 4px $primaryColor;
    left: -57.85px;
    background: $grayShade8;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
    top: 5px;
  }

  .ra-body-light {
    .timeline,
    .ra-timeline-time {
      color: $black;
    }
    .timeline {
      border-left: 4px solid $black;
      .event:after {
        box-shadow: 0 0 0 4px $black;
        background: white;
      }
    }
  }

  .ra-body-dark .ra-timeline-tip {
    color: $white;
  }

  .ra-body-dark .ra-timeline-leave {
    color: $white;
    background-color: $canvasColor;
  }

  .ra-body-light .ra-acteamo-logo {
    filter: invert(100%);
  }

  .ra-body-dark .ra-fixiq-logo {
    filter: invert(100%);
  }

  .ra-body-dark .ra-grissli-logo {
    filter: invert(100%);
  }

  .ra-body-dark .ra-divante-logo {
    filter: invert(100%);
  }

  .ra-small-aka {
    padding-left: 6px;
    padding-right: 3px;

    font-size: 12px;
  }
</style>
