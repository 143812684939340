var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',_vm._g({staticClass:"ra-badge",class:{
    'status-primary': _vm.type === 'primary',
    'status-secondary': _vm.type === 'secondary',
    'status-warning': _vm.type === 'warning',
    'status-success': _vm.type === 'success',
    'status-danger': _vm.type === 'danger'
  },domProps:{"textContent":_vm._s(_vm.text)}},_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }